import React, {Component} from 'react';
import './styles.css';
import LoginForm from "./components/login-form-component";
import ChooseAction from "./components/choose-action-component";
import OrderProducts from "./components/order-products-component";
import ReturnProducts from "./components/return-products-component";
import logo from './images/tvplay-logo.png';
import { Alert, Container, Row, Col } from 'reactstrap';
import { Switch, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import {Redirect} from "react-router";
library.add(faArrowAltCircleLeft);

class AppContainer extends Component {
    constructor(props) {
        super(props);
        this.updateErrorMessage = this.updateErrorMessage.bind(this);
        this.updateSuccessMessage = this.updateSuccessMessage.bind(this);
        this.onAlertDismiss = this.onAlertDismiss.bind(this);
        this.updateLoggedInStatus = this.updateLoggedInStatus.bind(this);
        this.state = {
            showAlert: false,
            showSuccessAlert: false,
            errorMessage: '',
            successMessage: '',
            loggedIn: false
        }
    }

    updateErrorMessage(message) {
        this.setState({
            showSuccessAlert: false,
            showAlert: true,
            errorMessage: message
        });
    }

    updateSuccessMessage(message) {
        this.setState({
            showAlert: false,
            showSuccessAlert: true,
            successMessage: message
        });
    }

    onAlertDismiss() {
        this.setState({
            showAlert: false,
            showSuccessAlert: false,
        });
    }

    updateLoggedInStatus() {
        this.setState({
            loggedIn: true
        });
    }

    render() {
        return (
            <Container>
                <Row id={"headerRow"}>
                    <Col>
                        <header className="header">
                            <Alert className={"alert"} color="danger" isOpen={this.state.showAlert} toggle={this.onAlertDismiss}>
                                {this.state.errorMessage}
                            </Alert>
                            <Alert className={"successAlert"} color="success" isOpen={this.state.showSuccessAlert} toggle={this.onAlertDismiss}>
                                {this.state.successMessage}
                            </Alert>
                            <img id={"logo"} src={logo} alt={"logo"}/>
                        </header>
                    </Col>
                </Row>
                <Row id={"bodyRow"}>
                    <Col>
                        <Switch>
                            <Route exact path='/' render={()=><LoginForm updateErrorMessage={this.updateErrorMessage} updateLoggedInStatus={this.updateLoggedInStatus}/>}/>
                            <Route exact path='/chooseaction' render={() => (this.state.loggedIn ? (<ChooseAction/>) : (<Redirect to="/"/>))}/>
                            <Route exact path='/orderproducts' render={()=> (this.state.loggedIn ?
                                                                            (<OrderProducts updateErrorMessage={this.updateErrorMessage} updateSuccessMessage={this.updateSuccessMessage}/>) :
                                                                            (<Redirect to="/"/>))}/>
                            <Route exact path='/returnproducts' render={()=> (this.state.loggedIn ?
                                                                            (<ReturnProducts updateErrorMessage={this.updateErrorMessage} updateSuccessMessage={this.updateSuccessMessage}/>) :
                                                                            (<Redirect to="/"/>))}/>
                        </Switch>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default AppContainer;

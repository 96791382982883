import React, {Component} from "react";
import {Button, Form, FormGroup, Input, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {withRouter} from "react-router-dom";
import LoadingIndicator from "react-loading-indicator";
import {url} from "../backendURL";

const BackBtn = withRouter(({ history }) => (
    <Button type='button' onClick={() => { history.push('/chooseaction') }}><FontAwesomeIcon icon="arrow-alt-circle-left" /> Back</Button>
));

class OrderProducts extends Component {
    constructor(props) {
        super(props);
        this.changeQuantity = this.changeQuantity.bind(this);
        this.validateOrder = this.validateOrder.bind(this);
        this.state = {
            products: [],
            isLoading: true,
            orderBtnClicked: false
        };
    }

    componentDidMount() {
        this.getProducts();
    }

    validateOrder() {
        if (!this.state.orderBtnClicked) {
            let products = this.getProductsWithAmount();
            if (products == ""){
                this.props.updateErrorMessage("Please enter amount");
                return;
            }
            if (!this.checkIfNumeric(products)){
                this.props.updateErrorMessage("Please enter only numerical values");
                return;
            }

            this.setState({orderBtnClicked: true});
            this.confirmOrder(products);
        }
    }

    addUserFullName(products) {
        let userFullName = localStorage.getItem('userFullName');
        products.push(userFullName);
        return products;
    }

    checkIfNumeric(products) {
        for (let i = 0; i < products.length; i ++) {
            let isnum = /^\d+$/.test(products[i].amount);
            if (!isnum) {
                return false;
            }
        }
        return true;
    }

    confirmOrder(products) {
        products = this.addUserFullName(products);
        let country = localStorage.getItem('country');

        fetch(url + '/public/order?country=' + country, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(products),
        }).then(function(response){
            return response.json();
        }).then(data => {
            this.checkResponse(data);
        }).catch(function(err){
            console.log(err)
        });
    }

    checkResponse(data) {
        this.setState({orderBtnClicked: false});

        if (data.status === "success"){
            this.props.updateSuccessMessage("Order confirmed!");
            this.clearFields();
        }
        else {
            this.props.updateErrorMessage("Unsuccessful Order. Error code: " + data.errorCode + ". Error field: "  + data.errorField)
        }
    }

    clearFields() {
        let products = this.state.products;
        products.forEach(function(product) {
            product.amount = "";
        });
        this.setState({
            products,
        });
    }

    changeQuantity(event) {
        const products = this.state.products;
        let index = products.findIndex(p => p.productID == event.target.id);
        products[index].amount = event.target.value;
        this.setState({
            products,
        });
    }

    getProducts() {
        let country = localStorage.getItem('country');
        fetch(url + '/public/order?country=' + country, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(function(response){
            return response.json();
        }).then(data => {
            this.setState({products: data, isLoading : false});
        }).catch(function(err){
            console.log(err)
        });
    }

    getProductsWithAmount() {
        return this.state.products.filter(p => {
            return p.amount !== null && p.amount !== "";
        });
    }

    renderRow = row => {
        return (<Row key={row.productID}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Input type="text" id="productName" value={row.name} readOnly />
            </FormGroup>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Input type="text" id={row.productID} placeholder="Enter qty" value={row.amount} onChange={this.changeQuantity}/>
            </FormGroup>
            <FormGroup id={"unit"}>
                <Input type="text" id="unit" value={row.unitName} readOnly />
            </FormGroup>
        </Row>);
    };

    renderRows = () => {
      return this.state.products.map(row => this.renderRow(row));
    };

    render() {
        return (this.state.isLoading ?
                <div className={"loader"}>
                    <h3>Please wait, loading...</h3>
                    <LoadingIndicator />
                </div> :
            <div className={"orderForm"}>
                <Form>
                    {this.renderRows()}
                    <div className="actionButtons">
                        <BackBtn/>
                        <Button onClick={this.validateOrder}>Confirm order</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

export default OrderProducts;

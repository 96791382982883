import React, {Component} from "react";
import { Button } from 'reactstrap';
import '../styles.css';
import {withRouter} from "react-router-dom";

const OrderProdBtn = withRouter(({ history }) => (
    <Button size = "lg" type='button' onClick={() => { history.push('/orderproducts') }}>Order products</Button>
));
const ReturnProdBtn = withRouter(({ history }) => (
    <Button size = "lg" type='button' onClick={() => { history.push('/returnproducts') }}>Return products</Button>
));

class ChooseAction extends Component {
    render() {
        return (
            <div className="actionButtons">
                <OrderProdBtn/>
                <ReturnProdBtn/>
            </div>
        );
    }
}

export default ChooseAction;